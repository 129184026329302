import { useState } from "react";
import * as React from 'react';
import { useMutation, gql } from "@apollo/client";

import { Text, Heading, Stack, Container, Input, FormControl, FormLabel, Button, Link, Box, Icon, InputGroup, InputRightElement } from "@chakra-ui/react"
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai"

import { Logo } from "../Logo/Logo";
import { GET_USER } from "../hooks/useAuth";

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: {
      login: $login
      password: $password
    }) {
      status
    }
  }
`;

 const LogInForm: React.FC = () => {

  const [passwordShown, setPasswordShown] = useState(false);
   // Password toggle handler
  function togglePassword() {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  }

   const [logIn, { loading, error }] = useMutation(LOG_IN, {
     refetchQueries: [{
      query: GET_USER
    }]
  });
  
  const errorMessage = error?.message || '';
  const isEmailValid =
    !errorMessage.includes('empty_email') &&
    !errorMessage.includes('empty_username') &&
    !errorMessage.includes('invalid_email') &&
    !errorMessage.includes('invalid_username');
  const isPasswordValid =
    !errorMessage.includes('empty_password') &&
    !errorMessage.includes('incorrect_password');
  
   function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    
    event.preventDefault();
    const data:any = new FormData(event.currentTarget);
    
     const values = Object.fromEntries(data);
     
     const email = values.login
     const password = values.password

    logIn({
      variables: {
        login: email,
        password
      },
    }).catch(error => {
      console.error(error);
    });
  }

  return (
     <Container maxW={{base: 'auto', md: "448px"}} py={{ base: '12', md: '16' }} pb={20} px="0px" position="relative">
      <Stack spacing="8" maxW={{base: 'auto', md: "448px"}} px={{base: '16px', md: "0px"}}>
        <Stack spacing="6" alignItems="center">
          <Logo />
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center" mt="40px !important">
            <Heading size="xl">
              Sign in to your account
            </Heading>
            <Text color="gray.600">Don’t have an account?  <Link href="/register" color="blue.600" >
            Register here
          </Link></Text>
          </Stack>
        </Stack>
        <Stack spacing="6" background="white" padding="40px" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);">
          <form method="post" onSubmit={handleSubmit}>
            <FormControl pb="24px">
              <FormLabel htmlFor="email">Email Address</FormLabel>
               
              <Input id="log-in-email" type="email" name="login" autoComplete="username" required />
              {!isEmailValid ? (
                <Text color="red" className="error-message">Invalid email. Please try again.</Text>
              ) : null}
            </FormControl>
            <FormControl>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <FormLabel htmlFor="password" >Password</FormLabel>
                 <Link href="/forgot-password" marginBottom="2"> <Button variant="link" colorScheme="blue" size="sm"> Forgot password? </Button></Link>
                </Box>
                <InputGroup>
                <Input id="log-in-password" name="password" autoComplete="current-password" required type={passwordShown ? "text" : "password"} />
                  <InputRightElement>
                    <Button onClick={togglePassword} background="transparent" color="gray.700" _hover={{ bg: 'transparent', border: "none" }} _focus={{ border: "none"}} >{passwordShown ? <Icon as={AiFillEyeInvisible} ></Icon> : <Icon as={AiFillEye}></Icon>}</Button>
                </InputRightElement>
              </InputGroup>
               {!isPasswordValid ? (
                    <Text color="red" className="error-message">Invalid password. Please try again.</Text>
                  ) : null}
              </FormControl>
              <Stack pt="24px">
                <Button type="submit" height="48px">{loading ? 'Signing in...' : 'Sign in'}</Button>
          </Stack>
          </form>
        </Stack>
      </Stack>
  </Container>
  )
}

export default LogInForm;