import * as React from "react";

import Layout from "../components/Base/Layout";
import LogInForm from "../components/Login/LoginForm";
import { SvgBackground } from "../components/Base/SvgLogo";
import UnAuthContent from "../components/Login/UnAuthContent";

export default function LogIn() {
  
  return (
    <Layout>
      <UnAuthContent>
        <SvgBackground position="absolute" bottom="0" right="0"></SvgBackground>
        <LogInForm />
      </UnAuthContent>
    </Layout>
  );
}